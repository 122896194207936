.Main_blog {
  background-color: #4fa095;
  height: 682px;
}
.Welcomee {
  font-size: 40px;
  /* margin-top: 8%; */
  text-align: center;
}

  /* text-align: center;
  background: white;
  padding: 20px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  border-radius: 10px; */
  
  


@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

/* .container { */
  /* max-width: 100vw;
	 display: flex;
	 grid-template-columns:0px repeat(3,310px); 
	 grid-gap: 35px;
	 margin: 0 auto;
	 padding: 40px 0; */
/* } */

.swiper-wrapper {
  margin-left: -35%;
}
.container .card {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 0 auto;
  background: #000;
  border-radius: 15px;
  box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);
}
.container .card .face {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .card .face.face1 {
  box-sizing: border-box;
  padding: 20px;
}
.container .card .face.face1 h2 {
  margin: 0;
  padding: 0;
}
.container .card .face.face1 .java {
  background-color: #fffc00;
  font-family: "Inter", sans-serif;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container .card .face.face1 .react {
  font-family: "Inter", sans-serif;
  background-color: #fffc00;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container .card .face.face1 .python {
  font-family: "Inter", sans-serif;
  background-color: #fffc00;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container .card .face.face1 .html {
  font-family: "Inter", sans-serif;
  background-color: #fffc00;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container .card .face.face1 .bootstrap {
  font-family: "Inter", sans-serif;
  background-color: #fffc00;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container .card .face.face1 .css {
  font-family: "Inter", sans-serif;
  background-color: #fffc00;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container .card .face.face2 {
  transition: 0.5s;
}
.container .card .face.face2 h2 {
  margin: 0;
  padding: 0;
  font-size: 10em;
  color: #fff;
  transition: 0.5s;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.container .card:hover .face.face2 {
  height: 60px;
}
.container .card:hover .face.face2 h2 {
  font-size: 2em;
}
.container .card:nth-child(1) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #fffc00 0%,
    #fc00ff 45%,
    #00fffc 100%
  );
  border-radius: 15px;
}
.container .card:nth-child(2) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #fc00ff 0%,
    #00fffc 45%,
    #fffc00 100%
  );
  border-radius: 15px;
}
.container .card:nth-child(3) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #00fffc 0%,
    #fc00ff 45%,
    #fffc00 100%
  );
  border-radius: 15px;
}
.container .card:nth-child(4) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #00fffc 0%,
    #fc00ff 45%,
    #fffc00 100%
  );
  border-radius: 15px;
}
.container .card:nth-child(5) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #00fffc 0%,
    #fc00ff 45%,
    #fffc00 100%
  );
  border-radius: 15px;
}
.container .card:nth-child(6) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #00fffc 0%,
    #fc00ff 45%,
    #fffc00 100%
  );
  border-radius: 15px;
}
.container .card:nth-child(7) .face.face2 {
  background-image: linear-gradient(
    40deg,
    #00fffc 0%,
    #fc00ff 45%,
    #fffc00 100%
  );
  border-radius: 15px;
}



@media screen and (max-width: 830px) {
  .main_card {
    height: 400px;
      width: 240px;
      margin-left: 38%;
    }
    .container .card{
      width: 228px;
        height: 370px;
      }
}

@media screen and (max-width:425px) {
  .main_card {
    margin-left: 54%;
  }
  .container .card{
	width: 228px;
    height: 370px;
  }
}

.Conatact{
    font-size:20px;
    /* margin-top: 12%; */
    text-align: center;
    color: aliceblue;
    user-select: none;
    background-color: #e6eff3;
}
.numberr{
  display: grid;
  text-decoration: none;
}
.contact_p{
  display: flex;
}
.headoff{
    color: black;
    font-size: 30px;
}
.btn-back-to-top {
    bottom: 20px;
    right: 20px;
    display: none;
  }
  .btn{
      margin-left: 90%;
}

/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
.containere{
  width: 85%;
  border-radius: 6px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
}
.containere .contentt{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.col-xl-3 {
  flex: 0 0 auto;
  width: 25%;
}
.containere .contentt .left-side{
  width: 25%;
  height: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.contentt .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.contentt .left-side .details{
  margin: 14px;
  text-align: center;
}
.contentt .left-side .details i{
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.contentt .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
.contentt .left-side .details .text-one,
.contentt .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
}
.containere .contentt .right-side{
  width: 100%;
  /* margin-left: 75px; */
}
.contentt .right-side .topic-text{
  font-size: 17px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box{
  height: 64px;
  width: 100%;
  margin: 12px 0;
  margin-bottom: 0px;
}
.right-side .input-box input,
.right-side .input-box textarea{
  height: 80%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #bcc0dd;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.submit{
    width: 5%;
}
/* .right-side .message-box{
  min-height: 110px;
} */
.right-side .input-box textarea{
  padding-top: 6px;
}
.error{
    color: rgb(87, 36, 36);
    background-color:rgb(255, 176, 176) ;
    border-radius: 5px;
    text-align: center
  }
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button button[type="button"]{
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button button[type="button"]:hover{
  background: #5029bc;
  transition: ease-in 0.3s;
}

@media (max-width: 950px) {
  .containere{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .containere .contentt .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {
  .containere{
    margin: 40px 0;
    height: 100%;
  }
  .containere .contentt{
    flex-direction: column-reverse;
  }
 .containere .contentt .left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 .containere .contentt .left-side::before{
   display: none;
 }
 .containere .contentt .right-side{
   width: 100%;
   margin-left: 0;
 }
}

@media (max-width: 500px) {
.contact_p{
  display: flow-root;
  justify-content: center;
  width: 80%;
}
.containere .contentt .right-side{
  width: 100%;
  margin-left: 0;
}
}

@media screen and (max-width: 930px) {
  .contact_p{
    display: flow-root;
    justify-content: center;
  }
  .row{
    justify-content: center;
  }
}

@media screen and (max-width:1030px) {
  .row{
    justify-content: center;
  }
  .comp {
    width: 20.333333%;
  }
  .containere{
    width: 50%;
  }
}
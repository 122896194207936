.header:hover {
  background-color: rgba(15, 233, 226, 0.623);
  border-radius: 30px 0px 0px 30px;
}

.navbar-expand-lg .navbar-collapse {
  padding-right: 0px;
  overflow-y: clip;
}
.navbar-light .navbar-nav .nav-link {
  text-align: center;
}
.navbar {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background-color: rgb(230, 239, 243);
}
.navbar-nav > .active > a {
  font-weight: bold;
  background-color: rgba(15, 233, 226, 0.623);
  border-radius: 30px 0px 0px 30px;
}

.navbar-toggler {
  margin-left: 86%;
}
.logo {
  display: grid;
}
.namesign {
  background-color: #81d1f3;
  height: 110vh;
  color: black;
  font-size: 90px;
  font-family: "Bodoni MT Poster";
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1030;
}
.navbar-expand-lg .navbar-nav {
  background-color: cadetblue;
  border-radius: 30px 0px 0px 30px;
}
@media (max-width: 600px) {
  .navbar-expand-lg .navbar-nav{
    width: 100%;
  }
}
.Main_edu{
    background-color: #BAD1C2;
}
.educationn{
    text-align: center;
}
.Welcomeee{
    font-size: 40px;
    text-align: center;
}
.text_dd{
    /* margin-top: 10%; */
    /* text-align: center; */
    display: grid;
}
.education{
    display: flex;
}
.logoo{
    margin-top: 3%;
    margin-left: 10%;
    height:300px;
    width: 500px;
}
.basic{
    margin-left: 9%;
    font-size: 40px;
}

@media (max-width:375px){

    .education{
        display: grid;
    }
    .logoo{
        margin-top: 3%;
        height: 182px;
        width: 250px;
    }
    .basic{
        margin-left: 4%;
    }
}

@media (max-width:600px){
    .Main_edu{
        margin-top: 50%;
    }
    .education{
        display: grid;
    }
    .logoo{
        margin-top: 3%;
        height: 182px;
        width: 250px;
    }
    .basic{
        margin-left: 4%;
    }
}

@media (max-width:900px){
    .logoo{
        margin-top: 3%;
        height: 182px;
        width: 250px;
    }
    .basic{
        margin-left: 10%;
    }
}
.Main {
  /* background-image: url("/public/image/pexels-pixabay-355770.jpg"); */
  /* background-size: cover; */
  height: 650px;
  background: #f6f6c9;
  font-family: 'Inter', sans-serif;
  margin-top: -45px;
}
.aboutme {
  font-size: 20px;
  width: 78%;
}
.Welcome {
  margin-top: 7%;
  font-size: 20px;
  margin-left: 5%;
  display: grid;
}
.felogo {
  margin-top: 5%;
  height: 300px;
  width: 530px;
}
.text_d {
  display: flex;
}
.imgdiv {
  display: flex;
}
/* .hcs{
    margin-left: 8%;
    width: 300px;
} */
/* .hcss{
    margin-left: 7%;
    width: 300px;
} */
.banner {
  margin-top: 5%;
}
.logos {
  height: 170px;
  width: 500px;
}
.work {
  margin-left: 40%;
  font-size: 50px;
}
/* .skills{
    margin-left: 5%;
    margin-top: 10%;
    font-size: 20px;
} */
.field {
  margin-top: -25%;
  margin-left: 57%;
  font-size: 40px;
}

@media (max-width: 375px) {
   
  .aboutme {
    text-align: center;
  }
  .text_d {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .felogo {
    width: 390px;
  }
  /* .work{
        margin-top: 10%;
        margin-left: 5%;
        font-size: 50px;
    } */
  /* .logos{
        height: 170px;
        width: 500px;
    } */
  /* .imgdiv{
        display: grid;
    } */
  /* .img_{
        display: grid;
    } */
  /* .skills{
        margin-top: 30%;
        text-align: center;
    } */
}

@media (max-width: 600px) {
  .Main{
    height: 770px;
   }
  .text_d {
    display: grid;
  }
  .felogo {
    width: 390px;
  }
  /* .work{
        margin-top: 10%;
        margin-left: 10%;
        font-size: 50px;
    }
    .logos{
        height: 170px;
        width: 500px;
    }
    .imgdiv{
        display: grid;
    }
    .img_{
        display: grid;
    }
    .skills{
        margin-top: 30%;
        margin-right: 20%;
    } */
}

@media (max-width: 900px) {
  .felogo {
    width: 390px;
  }
  /* .work{
        margin-top: 10%;
        margin-left: 10%;
        font-size: 50px;
    } */
  /* .logos{
        height: 170px;
        width: 500px;
    }
    .img_{
        display: grid;
    } */
  /* .skills{
        margin-top: 11%;
        font-size: 20px;
    } */
}

@media (max-width: 1200px) {
  /* .aboutme{
        margin-left: 14px;
        width: 273px;
    } */
  .felogo {
    width: 390px;
  }
  /* .work{
        margin-top: 10%;
        margin-left: 10%;
        font-size: 50px;
    }
    .logos{
        height: 170px;
        width: 500px;
    } */
  /* .skills{
        margin-left: 8%;
        margin-top: 34%;
        font-size: 20px;
    } */
}
